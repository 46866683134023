// FAQ
html body .p-faq {
  width: 100%;
  padding: 0 10px;
  margin: 40px auto 20px;
  @media #{$pc} {
    $padding: 20;
    max-width: (980 + $padding*2)*1px;
    margin-top: 80px;
    padding: 0 $padding+px;
  }
}