// ピックアップ/おすすめコンテンツ
.p-home-topics{ 
  $var: "";
    padding: 74px 0 80px;
    @media #{$pc} {
      padding: 145px 0 133px;
    }
  // ピックアップ/おすすめコンテンツ > 見出し
  &-heading {
      margin: 0;
    &__ja {
        font-size: 30px;
        font-weight: 400;
        letter-spacing: 2.5px;
        line-height: round(46/30*10)/10;
        text-align: center;
        display: block;
        margin: 0;
        @media #{$pc} {
          font-size: 42px;
          letter-spacing: 3.5px;
          line-height: round(42/60*10)/10;
        }
    }
    &__en {
        font-family: Lato;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 2px;
        line-height: 14px;
        text-align: center;
        text-transform: uppercase;
        display: block;
        margin: 21px 0 0;
        @media #{$pc} {
          margin-top: 33px;
        }
    }
  }
  // ピックアップ/おすすめコンテンツ > コンテナ
  &__body {
      margin: 59px 0 0;
      @media #{$pc} {
        margin-top: 79px;
      }
  }
  // ピックアップ/おすすめコンテンツ > ボタンコンテナ
  &__btns {
      margin: 63px 0 0;
      @media #{$pc} {
        margin-top: 120px;
      }
    .c-btn {
        font-family: Lato;
        margin: 0 auto;
    }
  }
}
