.l-footer {
  //margin: 60px 0 0;
  margin: 80px 0 0;
  overflow: hidden;
  @media #{$pc} {
      margin-top: 120px;
  }
}

// フッター > ナビ
.c-footer-nav {
$var: "";
&__list {
    list-style: none;
    padding: 0;
    margin: 0 20px;
    @media #{$pc} {
      display: flex;
      justify-content: space-between;
      max-width: (1100+10*2)*1px;
      padding: 0 10px;
      margin: auto;
    }
}
&__item {
    padding: 0;
    margin: 0;
    @media #{$pc} {
      width: 346/1100*100%;
    }
  & + & {
      margin-top: 20px;
      @media #{$pc} {
        margin-top: 0;
      }
  }
}
&__anchor {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.45;
    text-decoration: none;
    display: flex;
    align-items: center;
    width: 100%;
    //height: 80px;
    background: #000;
    position: relative;
    padding-right: 18px;
    &__info {
      padding-right: 22px;
    }
}
&__arrow {
    display: block;
    width: 18px;
    height: 10px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: calc(50% - 5px);
    right: 20px;
    @media #{$pc} {
      right: 20/346*100%;
    }
}
&__img {
    display: block;
    width: 80px;
    height: 80px;
    margin-right: 20px;
    @media #{$pc} {
        width: 80/346*100%;
        height: auto;
    }
}
}

// フッター > アドレス
.c-footer-address {
$var: #fff;
  font-style: normal;
  margin: 60px 0 0;
  @media #{$pc} {
    margin-top: 96px;
  }
&__heading {
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    margin: 0;
}
&__text {
    font-size: 14px;
    line-height: 2;
    text-align: center;
    margin: 21px 0 0;
  & + & {
      margin-top: 0;
  }
}
}

// フッター > バナー
.c-footer-bnr {
  $var: #fff;
  width: 100%;
  text-align: center;
  @media #{$pc} {
    width: 346/1100*100%;
    margin: 0 auto;
  }
  /*/ for debug
  background: url(/_devOnly/c-footer-bnr.png) center 0px no-repeat;
  > * { opacity: .65; }
  // */
&__wrapper {
  padding: 0 20px;
  max-width: 1120px;
  margin: 54px auto 0;
  @media #{$pc} {
    padding: 0 10px;
    margin-top: 106px;
  }
}
&__title {
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
}
&__anchor {
    text-decoration: none;
    display: block;
    height: 100%;
    background: #fff;
    padding: 0 5px 10px;
}
&__img {
    display: block;
    width: 100%;
    max-width: 220px;
    margin: auto;
    @media #{$pc} {
      max-width: 200px;
    }
}
&__note {
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.45;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 180px;
    background: #888;
    border-radius: 12px;
    margin: 1px auto 0;
    padding: 2px 4px;
}
}

// フッター > ユーティリティナビ
.c-footer-navUtil {
$var: #fff;
&__list {
    font-size: 13px;
    line-height: 1;
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 41px 0 0;
    @media #{$pc} {
      margin-top: 101px;
    }
}
&__item {
    font-size: 13px;
    line-height: 1;
  & + & {
      padding-left: 21px;
      margin-left: 20px;
      position: relative;
    &::before {
        content: "";
        display: block;
        width: 1px;
        height: 12px;
        background: #fff;
        position: absolute;
        top: calc(50% - 6px);
        left: 0;

    }
  }
}
&__anchor {
    color: #fff;
    font-size: 13px;
    line-height: 1;
    text-decoration: none;
}
}

// コピーライト
.c-copyright {
$var: #fff;
  font-family: Lato;
  font-size: 11px;
  font-weight: 400;
  line-height: (ceil(20/11*10)/10);
  text-align: center;
  margin: 29px 0 40px;
  @media #{$pc} {
    margin: 29px 0 92px;
  }
}