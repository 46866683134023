// 観覧マナー > マナー（個別）
.c-manners-item {
  $var: "";
    padding: 0 0 0 60px;
    position: relative;
    @media #{$pc} {
      min-height: 129px;
      padding-left: 140px;
    }
  & + & {
      margin-top: 49px;
      @media #{$pc} {
        margin-top: 58px;
      }
  }
  &:last-of-type {
      @media #{$pc} {
        min-height: auto;
      }
  }
  &.is-light {
    .c-manners-item__icon {
      width: 24px;
      left: 8px;
      @media #{$pc} {
        width: 58px;
        left: 21px;
      }
    }
  }
  // アイコン共通設定
  &__icon {
      display: block;
      width: 40px;
      height: 40px;
      background-position: center top;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 5px;
      left: 0;
      @media #{$pc} {
        width: 100px;
        height: 100px;
        top: 2px;
      }
  }
  // マナー > 見出し
  &__heading {
      font-size: 18px;
      font-weight: 700;
      line-height: ceil((28/18)*10)/10;
      margin: 16px 0 0;
    &.is-heading-only {
        @media #{$pc} {
          display: flex;
          align-items: center;
          height: 100px;
        }
    }
  }
  // マナー > 説明文
  &__text {
      font-size: 16px;
      font-weight: 400;
      line-height: ceil((28/16)*10)/10;
      margin: 16px 0 0;
      @media #{$pc} {
        margin-top: 20px;
      }
  }
}