// グロナビ
.c-navGlobal {
  $var: #fff;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 500;
}

// グロナビ > 言語切り替え
.c-navGlobal-lang {
  $var: #fff;
    line-height: 1;
    display: none;
    @media #{$pc} {
      font-size: 16/1080*100vw;
      display: block;
      margin: 0;
      position: absolute;
      top: 28px;
      right: 128px;
      z-index: 501;
    }
  &__anchor {
      color: #fff;
      font-size: 12px;
      line-height: 1;
      text-decoration: none;
      display: inline-flex;
      background: none;
      outline: none;
      border: none;
      cursor: pointer;
  }
  &__icon {
      content: "";
      display: inline-block;
      width: 14px;
      height: 14px;
      margin: 0 5px 0 0;
  }
  &--sp {
      padding: 26px 23px 0;
      @media #{$pc} {
        display: none;
      }
  }
}

// グロナビ > PC表示 : ダイジェストメニュー
.c-navGlobal-digest {
  $var: #fff;
    display: none;
    @media (min-width: 960px) {
      font-size: 16/1080*100vw;
      letter-spacing: -.1px;
      list-style: none;
      display: flex;
      width: auto;
      padding: 0;
      margin: 0 38px 0 0; //38px/16*1em
      z-index: 501;
    }
    @media (min-width: 1080px) {
      font-size: 16px;
      letter-spacing: 0;
    }
  &__item {
      padding: 0;
      margin: 0;
    //& + & {
      margin-left: 40/16*1em; // 40px
    //}
  }
  &__anchor {
      color: #fff;
      font-weight: 700;
      line-height: 1;
      text-decoration: none;
      white-space: nowrap;
  }
}

// グロナビ > トグルボタン
.c-navGlobal-toggleBtn {
  $var: #fff;
    width: 32px;
    height: 32px;
    margin: 0;
    z-index: 2;
    overflow: hidden;
    @media #{$pc} {
        width: 50px;
        height: 50px;
        transform: translateX(5px);
    }
  // ヘッダー > ナビ開閉ボタン
  &__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      appearance: none;
      background: none;
      padding: 0;
      border: 1px solid #fff;
      position: relative;
      cursor: pointer;
    &.is-opened {
      .c-navGlobal-toggleBtn__closed {
          opacity: 0;
      }
      .c-navGlobal-toggleBtn__opened {
          opacity: 1;
      }
    }
    &:focus {
        outline: none;
    }
  }
  &__closed,
  &__opened {
      content: "";
      width: 16px;
      height: 16px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
  }
  &__closed {
      transition: opacity .4s 0s ease-out;
  }
  &__opened {
      opacity: 0;
  }
}