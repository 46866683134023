// プライバシーポリシー
.p-privacy {
  $var: "";
    margin-top: 45px;
    @media #{$pc} {
      margin-top: 73px;
    }
  &__heading {
      font-size: 18px;
      font-weight: 700;
      line-height: ceil(24/18*10)/10;
      margin: 45px 0 0;
  }
  &__text {
      font-size: 16px;
      font-weight: 400;
      line-height: floor(27/16*100)/100;
      margin: 20px 0 0;
    & + .p-privacy__heading {
        margin-top: 53px;
    }
  }
}

// プライバーシーポリシー > 箇条書き
.p-privacy-list {
  $var: "";
    list-style: none;
    padding: 0;
    margin: 20px 0 0;
    counter-reset: ol;
  &__item {
      padding: 0 0 0 1.5em;
      margin: 20px 0 0;
      position: relative;
    &::before {
        width: 1em;
        height: 1em;
        position: absolute;
        top: 0;
        left: 0;
    }
    &:nth-child(1)::before {
        content: "①"
    }
    &:nth-child(2)::before {
        content: "②"
    }
    &:nth-child(3)::before {
        content: "③"
    }
    &:nth-child(4)::before {
        content: "④"
    }
    &:nth-child(5)::before {
        content: "⑤"
    }
    &:nth-child(6)::before {
        content: "⑥"
    }
  }
  &.is-2ndLv {
      margin-left: -1.5em;
    .p-privacy-list__item::before {
        counter-increment: ol;
        content: counter(ol, lower-roman);
    }
  }
}