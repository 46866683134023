// 視覚的に隠す（スクリーンリーダーでは読み上げ）
.u-visually-hidden {
		position: absolute;
		white-space: nowrap;
		width: 1px;
		height: 1px;
		overflow: hidden;
		border: 0;
		padding: 0;
		clip: rect(0 0 0 0);
		clip-path: inset(50%);
		margin: -1px;
}

// 視覚的に隠し、スクリーンリーダーでも読み上げないが、レイアウト上は残る
.u-invisible {
  visibility: hidden;
}