.p-about-header {
  $var: "";
    display: flex;
    flex-direction: column-reverse;
    margin: 60px 0 0;
    @media #{$pc} {
      flex-direction: row-reverse;
      justify-content: space-between;
      margin-top: 80px;
    }
  &__text {
      margin: 60px 0 0;
      @media #{$pc} {
        width: 610/1100*100%;
        margin-top: 0;
      }
    .c-heading--lv2 {
        margin-top: 0;
        @media #{$pc} {
          transform: translateY(-5px);
        }
    }
    p {
        font-size: 16px;
        font-weight: 400;
        line-height: ceil(38/16*10)/10;
        margin: 50px 0 0;
        @media #{$pc} {
          margin-top: 48px;
        }
      & + p {
          margin-top: 40px;
          @media #{$pc} {
            margin-top: 38px;
          }
      }
    }
  }
}

.p-about-header-img {
  $var: "";
    position: relative;
    margin: 0;
    @media #{$pc} {
      width: 440/1100*100%;
    }
  &__img {
      @media #{$pc} {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
  }
  &__text {
      font-feature-settings: "palt";
      font-size: 12px;
      font-weight: 400;
      line-height: 1;
      display: inline-flex;
      align-items: center;
      height: 30px;
      background-color: rgba(0, 0, 0, 0.6);
      padding: 0 20px;
      position: absolute;
      bottom: 0;
      right: 0;
  }
}

.p-about-body {
  $var: "";
    padding: 0 0 80px;
    margin: 51px 0 0;
    @media #{$pc} {
      padding-bottom: 100px;
      margin-top: 83px;
    }
  &__list {
      list-style: none;
      width: 328/375*100%;
      padding: 0;
      margin: 0 auto;
      @media #{$pc} {
        display: flex;
        justify-content: space-between;
        width: auto;
      }
  }
  &__item {
      padding: 0;
      margin: 0;
      @media #{$pc} {
        width: calc(33.3% - #{60*2/3*1px});
      }
    & + & {
        margin-top: 56px;
        @media #{$pc} {
          margin-top: 0;
        }
    }
  }
  &__text {
      font-size: 20px;
      font-weight: 700;
      line-height: 1;
      text-align: center;
      display: block;
      margin: 30px 0 0;
      @media #{$pc} {
        font-size: 20/850*100vw;
      }
      @media (min-width: 850px) {
        font-size: 20px;
      }
  }
  &__btnContainer {
      display: flex;
      justify-content: center;
      margin: 57px 0 0;
      @media #{$pc} {
        margin-top: 78px;
      }
  }
}

// ごあいさつ 
.p-about-greeting {
  $var: "";
    background: #222;
    padding-top: 61px;
    padding-bottom: 72px;
    @media #{$pc} {
      padding-top: 57px;
      padding-bottom: 52px;
    }
  // 見出し（上書き）
  .c-heading--lv2 {
      text-align: center;
      margin: 0;
  }
  &__paragraph {
      font-size: 16px;
      font-weight: 400;
      line-height: ceil(38/16*10)/10;
      text-align: justify;
      margin-top: 44px;
      @media #{$pc} {
        max-width: 980px;
        margin: 44px auto 0;
      }
    & + & {
        margin-top: 38px;
    }
  }
}

// ごあいさつ > プロフィール
.p-about-profile {
  $var: "";
    margin: 52px 0 0;
    @media #{$pc} {
      @include clearfix;
      max-width: 980px;
      margin: 55px auto 0;
    }
  &__photo {
      width: 160px;
      margin: 0 auto;
      @media #{$pc} {
          margin: 0 40px 0 0;
          float: left;
      }
  }
  &__title {
      font-size: 16px;
      font-weight: 700;
      line-height: 1;
      text-align: center;
      margin: 29px 0 0;
      @media #{$pc} {
        display: none;
      }
  }
  &__name {
      font-size: 24px;
      font-weight: 700;
      line-height: 1;
      text-align: center;
      margin: 21px 0 0;
      @media #{$pc} {
        text-align: left;
        margin-top: 34px;
      }
  }
  &__text {
      font-size: 16px;
      font-weight: 400;
      line-height: ceil(38/16*10)/10;
      text-align: justify;
      margin: 48px 0 0;
      @media #{$pc} {
        margin-top: 21px;
        overflow: hidden;
      }
  }
}

.p-about-outline {
  $var: "";
    background: #333;
    padding: 63px 0 0;
}


// 事業内容
.p-about-business {
  $var: "";
  &__listContainer {
      margin-top: 45px;
      @media #{$pc} {
        display: flex;
        margin-top: 50px;
      }
  }
  .c-list--ol-withoutDot {
      margin: 0;
    &:first-of-type {
        @media #{$pc} {
          margin-right: 157/740*100%;
        }
    }
  }
  & + .c-separator {
      @media #{$pc} {
        margin-top: 37px;
      }
  }
}


// 活動報告
.p-about-report {
  $var: "";
    margin-top: 60px;
    @media #{$pc} {
      margin-top: 57px;
    }
}


// 情報センターの資料と情報
.p-about-info {
  $var: "";
    padding-top: 60px;
    padding-bottom: 54px;
    @media #{$pc} {
      padding-top: 56px;
      padding-bottom: 54px;
    }
  &__text {
    font-size: 16px;
    font-weight: 400;
    line-height: ceil(38/16*10)/10;
    text-align: left;
    margin: 25px 0 0;
  }
}