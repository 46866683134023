// ページャー
.c-pager {
  $var: "";
    display: flex;
    justify-content: center;
  &__list {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0;
  }
  &__item {
      width: 40px;
      height: 40px;
    & + & {
        margin-left: 10px;
    }
  }
  &__anchor {
      color: #fff;
      font-size: 14px;
      line-height: 1;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background: #333;
    &.is-active {
        color: #333;
        background: #fff;
        pointer-events: none;
    }
  }
  &__prev,
  &__next {
      color: #fff;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -0.09px;
      line-height: 1;
      text-decoration: none;
      text-indent: -9999px;
      overflow: hidden;
      position: relative;
      @media #{$pc} {
        text-indent: 0;
        display: flex;
        align-items: center;
      }
    &.is-diabled {
        pointer-events: none;
        opacity: .4;
    }
  }
  &__prev {
      padding: 0 0 0 20px;
      margin: 0 30px 0 0;
      @media #{$pc} {
        padding: 0 0 0 28px;
        margin: 0 39px 0 0;
      }
    .c-pager__arrow {
        left: 0;
        transform: rotate(180deg);
    }
  }
  &__next {
      padding: 0 20px 0 0;
      margin: 0 0 0 30px;
      @media #{$pc} {
        padding: 0 28px 0 0;
        margin: 0 0 0 39px;
      }
  }
  &__arrow {
      display: block;
      width: 9px;
      height: 14px;
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: calc(50% - 7px);
      right: 0;
  }
}