// リスト
.c-list--ul {
  $var: "";
    list-style: none;
    padding: 0;
    margin: 0;
  .c-list__item {
      font-size: 16px;
      font-weight: 700;
      line-height: (28/16);
      padding: 0 0 0 20px;
      margin: 0;
      position: relative;
    &::before {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        background: rgba(231, 0, 0, 0.8);
        opacity: 0.85;
        position: absolute;
        top: 11px;
        left: 1px;
    }
    & + .c-list__item {
        margin-top: 22px;
    }
  }
}

// OLリスト
.c-list--ol {
  $var: "";
    counter-reset: ol;
    list-style: none;;
    padding: 0;
    margin: 32px 0 0;
  .c-list__item {
      font-size: 16px;
      font-weight: 700;
      line-height: ceil(28/16*10)/10;
      padding: 0 0 0 32px;
      margin: 0;
      position: relative;
    &::before {
        counter-increment: ol;
        content: counter(ol)".";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
    }
    & + .c-list__item {
        margin-top: 22px;
    }
  }
}

// OLリスト : 「.」なし
.c-list--ol-withoutDot {
  $var: "";
    counter-reset: ol;
    list-style: none;;
    padding: 0;
    margin: 23px 0 0;
  .c-list__item {
      font-size: 16px;
      font-weight: 700;
      line-height: ceil(28/16*10)/10;
      padding: 0 0 0 20px;
      margin: 0;
      position: relative;
    &::before {
        counter-increment: ol;
        content: counter(ol);
        display: block;
        position: absolute;
        top: 0;
        left: 0;
    }
    & + .c-list__item {
        margin-top: 22px;
    }
  }
}

// OLリスト : 「.」なし
.c-list--ol-alphabet {
  $var: "";
    counter-reset: ol;
    list-style: none;;
    padding: 0;
    margin: 23px 0 0;
  .c-list__item {
      font-size: 16px;
      font-weight: 700;
      line-height: ceil(28/16*10)/10;
      padding: 0 0 0 23px;
      margin: 0;
      position: relative;
    &::before {
        counter-increment: ol;
        content: counter(ol, upper-alpha)":";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
    }
    & + .c-list__item {
        margin-top: 22px;
    }
  }
}