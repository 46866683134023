// information > 一覧
.p-info-list {
  $var: "";
    display: flex;
    flex-direction: column-reverse;
    @media #{$pc} {
      flex-direction: row;
      justify-content: space-between;
      padding: 82px 0 0;
    }
  &__body {
      padding: 51px 0 0;
      @media #{$pc} {
        width: 720/980*100%;
        padding-top: 0;
      }
  }
  &__pager {
      margin-top: 40px;
      @media #{$pc} {
        margin-top: 60px;
      }
  }
  &__year {
      margin: 80px 0 0;
      @media #{$pc} {
        width: 180/980*100%;
        margin: 0;
      }
  }
  .c-info-list__item {
      padding-bottom: 28px;
      @media #{$pc} {
        padding-top: 9px;
        padding-bottom: 14px;
      }
    & + .c-info-list__item {
        margin-top: 26px;
        @media #{$pc} {
          margin-top: 20px;
        }
    }
  }
  .c-info-list__text {
      margin-top: 12px;
      @media #{$pc} {
        margin-top: 0;
      }
  }
}

// information > 一覧ページ > 年選択
.p-info-list-year {
  $var: "";
  &__list {
      list-style: none;
      padding: 0;
      margin: 0;
  }
  &__item {
      padding: 0;
      margin: 0;
  }
  &__anchor {
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      line-height: 1;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 40px;
      background: #000;
      @media #{$pc} {
        height: 62px;;
      }
    &.is-active {
        color: #333;
        background: #fff;
        pointer-events: none;
    }
  }
}