@mixin container {
		$padding-sp: 10px;
		width: 100%;
		max-width: 1040px;
		padding-left: $padding-sp;
		padding-right: $padding-sp;
		margin: 0 auto;
		@media #{$pc} {
			$padding-pc: 20px;
			padding-left: $padding-pc;
			padding-right: $padding-pc;
		}
}

@mixin visually-hidden {
	position: absolute;
	white-space: nowrap;
	width: 1px;
	height: 1px;
	overflow: hidden;
	border: 0;
	padding: 0;
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	margin: -1px;
}

// CSS Transition
@mixin transitionSetting($transitionPropaty: null) {
	backface-visibility: hidden;
	transition-property: $transitionPropaty;
	transition-duration: 0.3s;
	transition-delay: 0s;
	transition-timing-function: ease-out;
}

// clearfix
@mixin clearfix {
		*zoom: 1;
	&:before,
	&:after {
			content: " ";
			display: table;
	}
	&:after {
			clear: both;
	}
}

// This mixin can be used to set the object-fit:
// @include object-fit(contain);
// or object-fit and object-position:
// @include object-fit(cover, top);
@mixin object-fit($fit: fill, $position: null){
		-o-object-fit: $fit;
		object-fit: $fit;
	@if $position {
			-o-object-position: $position;
			object-position: $position;
			font-family: 'object-fit: #{$fit}; object-position: #{$position}';
	} @else {
			font-family: 'object-fit: #{$fit}';
	}
}
