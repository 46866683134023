// ローカルナビ
html body .c-navLocal {
  $var: "";
    padding: 0 10px;
    margin: 21px 0 0;
    @media #{$pc} {
      margin: 66px 0 0;
    }
}
.c-navLocal {
  &__list {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin: -4px 0 0 -4px;
      @media #{$pc} {
        justify-content: center;
        margin: 0;
      }
  }
  &__item {
    margin: 4px 0 0 4px;
    @media #{$pc} {
      margin: 0;
    }
  }
  &__anchor {
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      line-height: 1;
      text-decoration: none;
      display: block;
      width: 100%;
      height: 100%;
      padding: 13px 25px;
      background: #000;
      @media #{$pc} {
        padding: 23px 25px;
      }
    &--active {
        color: #333;
        background: #fff;
    }
  }
}