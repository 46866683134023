.c-navGlobal-toggleBody {
  $var: #fff;
    display: none;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, .85);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    //animation: .4s cubic-bezier(.5,0,0,1) 0s forwards navGlobalFadeOut;
    @media #{$pc} {
      height: 420px !important;
      background-color: rgba(0, 0, 0, .7);
    }
    /*/ for debug
    background: url(/_devOnly/menu--sp.png) center -2px no-repeat;
      @media #{$pc} {
        background: url(/_devOnly/menu--pc.png) center 0 no-repeat;
      }
    > * {
      opacity: .65;
    }
    // */
  // 展開状態
  &.is-opened {
      display: block;
      padding-bottom: 130px;
      z-index: 1;
      overflow-y: scroll;
      animation: .4s cubic-bezier(.5,0,0,1) 0s 1 forwards navGlobalFadeIn;
      -ms-overflow-style: none;
      scrollbar-width: none;
      @media #{$pc} {
        padding-bottom: 0;
      }
    &::-webkit-scrollbar {
        display: none;
    }
  }
}
@keyframes navGlobalFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes navGlobalFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


// グロナビ > 第1階層
.c-navGlobal-list {
  $var: "";
    list-style: none;
    padding: 0 20px 12px;
    margin: 84px 0 0;
    @media #{$pc} {
      display: flex;
      justify-content: space-between;
      max-width: (980 + 20*2)*1px;
      margin: 138px auto 0;
    }
  // グロナビ > 第1階層 > アイテム
  &__ctg {
      @media #{$pc} {
        width: 160/980*100%;
        margin-top: 0 !important;
      }
    & + & {
        margin-top: 37px;
    }
    // ピックアップ カテゴリー
    &.is-ctg--pickup {
        @media #{$pc} {
            width: 411/980*100%;
        }
    }
    // その他 カテゴリー
    &.is-ctg--others {
        margin-top: 0;
      .is-bordered:last-of-type {
          border-bottom: solid 1px #fff;
          @media #{$pc} {
            border-bottom: none;
          }
      }
    }
  }
  // グロナビ > 第1階層 > アイテム > 見出し
  &__ctgHeading {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 1;
      text-align: left;
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0 9px 4px;
      border: 1px solid #ffffff;
      @media #{$pc} {
          height: auto;
          padding: 0 0 20px;
          border: 0;
          border-bottom: 1px solid #ffffff;
      }
    // PC表示のみのメニュー
    &.is-pcOnly {
        display: none;
        @media #{$pc} {
          display: flex;
        }
    }
  }
  &__ctgHeadingSub.is-bordered {
      @media #{$pc} {
        margin-top: 22px;
      }
  }
  // 境界線ありメニュー
  .is-bordered {
      font-size: 14px;
      font-weight: 700;
      line-height: 1;
      display: block;
      width: 100%;
      padding: 13px 0;
      border-top: solid 1px #fff;
      @media #{$pc} {
          padding: 0;
          border: 0;
          margin-top: 20px !important;
      }
  }
}


// グロナビ > 第2階層
.c-navGlobal-ctgList {
  $var: "";
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 2px 0 0;
    @media #{$pc} {
      display: block;
    }
  // PC表示専用メニュー
  &.is-pcOnly {
      display: none;
      @media #{$pc} {
        display: block;
      }
  }
  // ユーティリティメニュー的なところ
  .is-ctg--others & {
      margin-top: 0;
  }
  // メニューアイテム
  &__item {
      font-size: 14px;
      font-weight: 700;
      line-height: 1;
      width: 50%;
      padding: 0;
      margin: 20px 0 0;
      @media #{$pc} {
        font-size: 13px;
        letter-spacing: -0.1px;
        width: 100%;
      }
      @media (min-width: 850px) {
        font-size: 14px;
      }
    &:nth-child(2n) {
        padding-left: 12px;
        @media #{$pc} {
          padding-left: 0;
        }
      .is-ctg--others & {
          padding-left: 0;
      }
    }
    .is-ctg--others & {
        margin-top: 0;
    }
  }
  &__anchor {
      color: #fff;
      text-decoration: none;
      display: block;
      line-height: 1.45;
      @media #{$pc} {
        display: inline;
      }
  }
  // ピックアップ/おすすめ（PC表示のみ）
  &--pickup {
      margin: 17px 0 0;
    .c-navGlobal-ctgList__item {
        font-weight: 400;
        line-height: 1.5;
        margin-top: 13px;
    }
    .c-navGlobal-ctgList__anchor {
        display: flex;
    }
  }
}

.c-navGlobal-pickup {
  $var: "";
  &__date {
      display: inline-block;
      width: 102px;
  }
  &__heading {
      width: calc(100% - 102px);
  }
}