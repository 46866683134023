// 新型コロナ対策のお願い
.p-guide-covid19 {
  $var: "";
    margin: 71px 0 0;
    @media #{$pc} {
      margin-top: 90px;
    }
  &__heading {
      font-size: 24px;
      font-weight: 700;
      line-height: (32/24);
      display: flex;
      align-items: center;
      margin: 0;
      @media #{$pc} {
        font-size: 28px;
        line-height: (32/28);
      }
    &::before {
        content: "";
    }
    &--lv2 {
        font-size: 20px;
        font-weight: 700;
        line-height: (32/20);
        margin: 40px 0 0;
        @media #{$pc} {
          margin-top: 24px;
        }
    }
  }
  &__headingIcon {
      display: inline-block;
      width: 40px;
      height: 41px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      margin-right: 10px;
      transform: translateY(2px);
      @media #{$pc} {
        width: 80px;
        height: 80px;
        margin-right: 20px;
        transform: none;
      }
  }
  .c-list--ul {
    margin-top: 29px;
  }
  .c-text__note {
    margin-top: 36px;
  }
  .u-container__item + .u-container__item {
    margin-top: 45px;
    @media #{$pc} {
      margin-top: 0;
    }
  }
}