// 過去の刊行物
.p-publications {
  $var: "";
  &__img {  
      padding: 0 20px;
      margin: 54px 0 0;
      @media #{$pc} {
        max-width: 1140px;
        margin: 95px auto 0;
      }
  }
  &__container {
      margin: 41px 0 0;
      @media #{$pc} {
        margin-top: 48px;
      }
  }
  &__text {
      //text-align: justify;
      margin: 0;
    & + & {
        margin-top: 38px;
    }
  }
  &__anchor {
      color: #fff;
      text-decoration: none;
  }
}