// 産業遺産
.p-heritage {
  $var: "";
    @media #{$pc} {
      height: 885px;
      margin-top: 73px;
      position: relative;
    }
}


// 産業遺産 > マップ
.p-heritage-block-map {
  $var: "";
    margin: 57px -10px 0;
    @media #{$pc} {
      margin: 0;
    }
  img {
      width: 100%;
      max-width: 991px;
      @media #{$pc} {
        padding: calc(280px - #{(280-101)/1140*100vw}) 0 0;
        margin: 0 16/1100*100% 0 auto;
      }
      @media (min-width: 1140px) {
        padding-top: 101px;
      }
  }
}


// 産業遺産 > エリアブロック
.p-heritage-block {
  $var: "";
    margin: 5px 0 0;
    @media #{$pc} {
      width: 260/1100*100%;
      margin: 0;
      position: absolute;
    }
  & + & {
      margin-top: 39px;
      @media #{$pc} {
        margin: 0;
      }
  }
  &.is-area01 {
      @media #{$pc} {
        top: 0;
        left: 377/1100*100%;
      }
  }
  &.is-area02 {
      @media #{$pc} {
        //top: 573px;
        //left: 820/1100*100%;
        bottom: 0;
        right: 0;
      }
  }
  &.is-area03 {
      @media #{$pc} {
        top: 547px;
        //left: 820/1100*100%;
        right: 0;
      }
  }
  &.is-area04 {
      @media #{$pc} {
        top: 409px;
        //left: 880/1100*100%;
        right: 0;
      }
  }
  &.is-area05 {
      @media #{$pc} {
        top: 0;
        left: 0;
      }
  }
  &.is-area06 {
      @media #{$pc} {
        //top: 346px;
        bottom: 0;
        left: 0;
      }
  }
  &.is-area07 {
      @media #{$pc} {
        bottom: 0;
        left: 479/1100*100%;
      }
  }
  &.is-area08 {
      @media #{$pc} {
        top: 142px;
        left: 0;
      }
  }
  // 産業遺産 > エリアブロック > タイトル
  &__title {
      font-family: Lato;
      font-size: 14px;
      font-weight: 700;
      line-height: round(30/14*10)/10;
      text-transform: uppercase;
      margin: 0;
  }
}


// 産業遺産 > エリアブロック > 遺産リスト
.p-heritage-table {
  $var: "";
    margin: 9px 0 0;
  & + & {
      margin-top: 12px;
  }
  // 産業遺産 > エリアブロック > 遺産リスト > エリア名
  &__prefecture {
      font-size: 16px;
      font-weight: 700;
      line-height: 1;
      margin: 0;
      @media #{$pc} {
        font-size: 15px;
      }
      @media (min-width: 800px) {
        font-size: 16px;
      }
  }
  // 産業遺産 > エリアブロック > 遺産リスト > リスト
  &__list {
      list-style: none;
      padding: 0;
      margin: 11px 0 0;
  }
  // 産業遺産 > エリアブロック > 遺産リスト > 遺産名
  &__item {
      display: flex;
      align-items: center;
      //height: 46px;
      background: #000;
      padding: 14px 10px;
      box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.3);
      @media #{$pc} {
        padding: 15px 10px;
      }
      @media (min-width: 800px) {
        padding: 14px 10px;
      }
    & + & {
        margin-top: 2px;
    }
  }
  // 産業遺産 > エリアブロック > 遺産リスト > アンカー
  &__anchor {
      color: #fff;
      font-feature-settings: "palt";
      font-size: 14px;
      font-weight: 400;
      line-height: round(18/14*10)/10;
      text-align: left;
      text-decoration: none;
      display: block;
      width: 100%;
      @media #{$pc} {
        font-size: 13px;
      }
      @media (min-width: 800px) {
        font-size: 14px;
      }
    &:focus {
        outline: 0;
    }
  }
}





// 遺産情報 > モーダル
.p-heritage-modal {
  &-wrapper {
      display: none;
  }
  $var: "";
    color: #333;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    background: rgba(255, 255, 255, .95);
    padding: 52px 30px 50px;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, .3);
    //overflow: scroll;
    /*
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    */
    @media #{$pc} {
      max-width: 980px;
      height: auto;
      padding: 31px 30px 30px;
      margin: auto;
      border-radius: 6px;
      position: relative;
    }
  // 遺産名上コピー
  &__copy {
      font-size: 20px;
      font-weight: 700;
      line-height: round(24/20*10)/10;
      text-align: left;
      margin: 0;
  }
  // 遺産名
  &__title {
      color: #fff;
      font-size: 24px;
      font-weight: 700;
      line-height: round(28/24*10)/10;
      text-align: left;
      display: inline-block;
      background: #000;
      padding: 4px 21px;
      margin: 23px 0 0;
  }
  // 遺産名（複数パターン）
  &__subTitle {
      font-size: 20px;
      font-weight: 700;
      line-height: 1;
      text-align: left;
      margin: 0;
    & + .p-heritage-modal__text {
        margin-top: 9px;
    }
  }
  // 遺産紹介文
  &__text {
      font-size: 18px;
      font-weight: 400;
      line-height: ceil(38/18*100)/100;
      text-align: justify;
      margin: 19px 0 0;
  }
  // 遺産画像
  //&__img {}
  // 「閉じる」ボタン > コンテナ
  &__btn {
      width: 260/315*100%;
      margin: 41px auto 0;
      @media #{$pc} {
        display: none;
      }
  }
  // 「閉じる」ボタン > ボタン
  &__close {
      font-size: 18px;
      font-weight: 700;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      appearance: none;
      width: 100%;
      height: 54px;
      background: none;
      padding: 0;
      border: 1px solid #aaa;
  }
  // 「×」ボタン
  &__closeBtn {
      display: block;
      appearance: none;
      background: none;
      padding: 5px;
      border: 0;
      position: absolute;
      top: 5px;
      right: 5px;
      @media #{$pc} {
        //display: none;
      }
    &:focus {
        outline: 0;
    }
  }
}


// 遺産情報 > モーダル > 見出し
.p-heritage-modal-heading {
  $var: "";
    line-height: 1;
    margin: 0;
  &__area,
  &__copy {
      font-size: 16px;
      font-weight: 700;
      line-height: 1;
      text-align: left;
  }
  &__copy {
      padding-left: 24px;
  }
}


//
.p-heritage-modal-heading-multi {
  $var: "";
    margin: 27px 0 0;
  & + .p-heritage-modal-body {
      @media #{$pc} {
        margin-top: 60px;
      }
  }
}


// 遺産情報 > モーダル > コンテナ
.p-heritage-modal-body {
  $var: "";
    margin: 27px 0 0;
    @media #{$pc} {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      overflow: hidden;
    }
  & + & {
      margin-top: 59px;
  }
  &__text {
      @media #{$pc} {
          width: 600/(980 - 30*2)*100%;
      }
  }
  &__img {
      width: 280/315*100%;
      margin: 30px auto 0;
      @media #{$pc} {
        width: 280/(980 - 30*2)*100%;
        margin: 0 0;
      }
  }
}


#modal-area1-hagihansharo {
  $var: "";
  /* for debug
  background: url(/_devOnly/heritage-modal-01--sp.png) center 0 no-repeat;
  @media #{$pc} {
    background: url(/_devOnly/heritage-modal-01--pc.png) center 0 no-repeat;
  }
  > * {
    opacity: .65;
  }
  // */
}
.p-heritage-modal {
  $var: "";
  /* for debug
  background: url(/_devOnly/heritage-modal-02--sp.png) center 0 no-repeat;
  @media #{$pc} {
    background: url(/_devOnly/heritage-modal-02--pc.png) center 0 no-repeat;
  }
  > * {
    opacity: .65;
  }
  // */
}