
// 展示 > 導入部
.p-exhibition-header {
  $var: "";
    padding: 51px 0 11px;
    @media #{$pc} {
      padding: 75px 0 11px;
    }
  // 見出し 調整
  .c-heading--lv2 {
      text-align: center;
      margin: 0;
  }
  // サブタイトル
  &__subTitle {
      font-size: 20px;
      font-weight: 700;
      line-height: ceil(32/20*10)/10;
      text-align: center;
      margin: 16px 0 0;
      @media #{$pc} {
          margin-top: 52px;
      }
  }
  // テキスト
  &__text {
      font-size: 16px;
      font-weight: 400;
      line-height: ceil(38/16*10)/10;
      text-align: justify;
      @media #{$pc} {
        max-width: 740px;
        margin: 24px auto 0;
      }
  }
  // 平面図
  &__map {
      margin: 30px 0 0;
      @media #{$pc} {
        margin-top: 52px;
      }
  }
  // 平面図 > 画像
  &__img {
      margin: 0 auto;
      @media #{$pc} {
        transform: translateX(5px);
      }
  }
}

// ゾーン別紹介
.p-exhibition-zone {
  $var: "";
    margin: 70px 0 0;
    @media #{$pc} {
      margin-top: 168px;
    }
  & + & {
      margin-top: 72px;
      @media #{$pc} {
          margin-top: 114px;
      }
  }
  // 説明文
  &__text {
      font-size: 14px;
      font-weight: 400;
      line-height: ceil(38/14*100)/100;
      text-align: justify;
      margin: 22px 0 0;
      @media #{$pc} {
        max-width: 740px;
        margin: 27px auto 0;
      }
  }
  &__listContainer {
      margin-top: 35px;
      @media #{$pc} {
        display: flex;
        max-width: 740px;
        margin: 21px auto 0;
      }
  }
  // アルファベットリスト 調整
  .c-list--ol-alphabet {
      margin: 0;
    &:first-of-type {
      @media #{$pc} {
        margin-right: 83/740*100%;
      }
    }
    .c-list__item {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: ceil(28/14*10)/10;
        padding-left: 23px;
      & + .c-list__item {
          margin-top: 10px;
      }
    }
  }

}

// ゾーン別紹介 > 見出し
.p-exhibition-zone-heading {
  $var: "";
    margin: 0;
    @media #{$pc} {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  &__box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 78px;
      background: #f00;
      @media #{$pc} {
        width: 240px;
        margin-right: 40px;
      }
    &:only-child {
        @media #{$pc} {
          margin-right: 0;
        }
    }
  }
  &__number {
      font-size: 16px;
      font-weight: 700;
      line-height: 1;
      text-align: center;
      display: block;
      transform: translateY(-5px);
  }
  &__title {
      font-size: 28px;
      font-weight: 700;
      line-height: 1;
      text-align: center;
      display: block;
  }
  &__leadText {
      font-size: 24px;
      font-weight: 700;
      line-height: ceil(32/24*10)/10;
      text-align: center;
      display: block;
      margin: 23px 0 0;
      @media #{$pc} {
        font-size: 28px;
        margin: 0;
      }
  }
}

// ゾーン別紹介 > 写真付き紹介
.p-exhibition-zone-figure {
  $var: "";
    margin: 33px 0 0;
    @media #{$pc} {
      margin-top: 50px;
    }
  .is-zone2 & {
      margin-top: 50px;
  }
  & + & {
    @media #{$pc} {
      margin-top: 63px;
    }
  }
  &__img {

  }
  &__caption {
      font-size: 20px;
      font-weight: 700;
      line-height: ceil(32/20*100)/100;
      text-align: left;
      margin: 25px 0 0;
      @media #{$pc} {
        max-width: 740px;
        margin: 25px auto 0;
      }
  }
  &__description {
      font-size: 16px;
      font-weight: 400;
      line-height: ceil(38/16*100)/100;
      text-align: justify;
      margin: 1px 0 0;
      @media #{$pc} {
        max-width: 740px;
        margin: 1px auto 0;
      }
  }
}

// ゾーン別紹介 > 資料室 > イメージ写真
.p-exhibition-zone-reference-img {
  $var: "";
    display: flex;
    margin: 38px 0 60px;
    @media #{$pc} {
      margin-top: 53px;
    }
  &__photo {
      width: 50%;
      height: 100%;
  }
}

// 展示 > ガイド案内
.p-exhibition-guide {
  $var: "";
    padding: 7px 0 0;
    margin-top: 60px;
    @media #{$pc} {
      max-width: 980px;
      padding-top: 15px;
      margin: 69px auto 0;
      position: relative;
    }
  &__container {
      margin: 25px 0 0;
      @media #{$pc} {
          display: flex;
          justify-content: space-between;
          margin-top: 29px;
      }
  }
  &__text {
      font-size: 16px;
      font-weight: 400;
      line-height: ceil(38/16*10)/10;
      text-align: justify;
      margin: 0;
      @media #{$pc} {
        width: 530/980*100%;
      }
  }
  &__imgBox {
      margin: 33px 0 0;
      @media #{$pc} {
        width: 390/980*100%;
        //padding-top: 171/980*100%;
        margin: 0;
      }
  }
  &__img01 {
      margin: auto;
      @media #{$pc} {
        width: 300/980*100%;
        margin: 0;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
      }
  }
  &__img02 {
      margin: auto;
      @media #{$pc} {
        width: 260/390*100%;
        padding-top: 35/390*100%;
        margin: 0;
      }
  }
}