
.p-guide-container {
  $var: "";
    margin-top: 10px;
    @media #{$pc} {
      margin-top: 59px;
    }
}

// 利用案内 > 開館時間・休館日
.p-guide-openingHours {
  $var: "";
    margin-top: 60px;
    @media #{$pc} {
      margin-top: 0;
    }
  &__text {
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      line-height: (28/14);
      margin: 16px 0 0;
  }
  .c-table {
      margin-bottom: 34px;
  }
}

// 利用案内 > 入場料
.p-guide-price {
  $var: "";
    margin-top: 50px;
    @media #{$pc} {
      margin-top: 0;
    }
}

// 利用案内 > 住所
.p-guide-address {
  $ver: "";
    margin-top: 80px;
  &__text {
      font-size: 16px;
      line-height: (28/16);
  }
  &__btnContainer {
      margin-top: 30px;
  }
}

// 利用案内 > バリアフリー情報
.p-guide-barrierFree {
  $ver: "";
    margin-top: 65px;
    @media #{$pc} {
      margin-top: 68px;
    }
  .c-list--ul {
      margin-top: 46px;
  }
}

// 利用案内 > お問い合わせ
.p-guide-contact {
  $var: "";
    margin-top: 62px;
    @media #{$pc} {
      margin-top: 68px;
    }
  &__container {
      @media #{$pc} {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
  }
  &__table {
      @media #{$pc} {
        width: 520/1100*100%
      }
    .c-table__header,
    .c-table__data {
        @media #{$pc} {
          width: 50%;
          padding-left: 20px;
        }
    }
  }
  &__reserve {
      font-size: 16px;
      line-height: (28/16);
      margin-top: 39px;
      @media #{$pc} {
        white-space: nowrap;
        width: 152/1100*100%;
        min-width: 9em;
        margin-top: 0;
        margin-left: 60/1100*100%
      }
  }
  &__btnContainer {
      margin-top: 24px;
      @media #{$pc} {
        flex: 1 1 auto;
        margin-top: 0;
      }
    .c-btn {
      @media #{$pc} {
        width: 100%;
        max-width: 260px;
      }
    }
  }
}