// テーブル
.c-table {
  $border: solid 1px #ccc;
    width: 100%;
    border: $border;
    border-collapse: collapse;
  // テーブル > th
  &__header {
      font-size: 16px;
      font-weight: 400;
      line-height: (20/16);
      text-align: left;
      vertical-align: middle;
      white-space: nowrap;
      padding: 17px 22px;
      border: $border;
  }
  // テーブル > td
  &__data {
      font-size: 16px;
      font-weight: 400;
      line-height: (20/16);
      vertical-align: middle;
      padding: 17px 10px;
      border: $border;
  }
}