// 区切り線
.c-separator {
  $var: "";
    width: 335/375*100%;
    height: 1px;
    background: #868686;
    padding: 0;
    border: none 0;
    margin: 55px auto 0;
    @media #{$pc} {
      width: 100%;
      max-width: 1100px;
      margin: 53px auto 0;
    }
  &:last-child {
      margin-bottom: 0;
  }
  &--noMargin {
    width: 100%;
  }
}