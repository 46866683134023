.page {
	&-inner {
		background: #fff;
		padding: 40px;
	}
}

@media (max-width: 767px) {
	.page {
		&-inner {
			padding: 10px;
		}
	}
}


.cal-disabled {
	abbr {
		color: #ccc;
		&::after {
			display: block;
			content: '×';
		}
	} 
}

.cal-maru {
	abbr {
		&::after {
			display: block;
			content: '○';
		}
	} 
}

.cal-sankaku {
	abbr {
		&::after {
			display: block;
			content: '△';
		}
	} 
}


.loading {
	height: 120px;
	width: 100%;
	position: relative;
	background: #f0f0f0;
	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		font-size: 40px;
		animation: spinner 1.5s infinite linear forwards;
	}
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: rgba(0, 0, 0, .6);
	z-index: 999;
	&-inner {
		position: absolute;
		width: 80%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: #fff;
		text-align: center;
		padding: 20px;
		box-sizing: border-box;
	}
	&-close {
		position: absolute;
		cursor: pointer;
		top: 10px;
		right: 10px;
		svg {
			line-height: 1;
			font-size: 30px;
			margin: 0;
			padding: 0;
			vertical-align: bottom;
		}
	}
	h2 {
		margin: 0 0 20px;
	}
	&-list {
		margin: 0;
	}
	&-item {
		display: flex;
		justify-content: space-between;
		border: 1px solid #ccc;
		border-radius: 6px;
		padding: 24px 20px;
		margin: 0 0 14px;
		cursor: pointer;
		dt ,
		dd {
			margin: 0;
			padding: 0;
		}
		&:last-child {
			margin: 0;
		}
		&-disable {
			background: #999;
			border: 1px solid #999;
			color: #666;
			cursor: not-allowed;
		}
	}
}

@media (max-width: 767px) {
	.overlay {
		&-inner {
			width: 90%;
			padding: 12px;
		}
		h2 {
			margin: 0 0 10px;
		}
		&-list {
			margin: 0;
		}
		&-item {
			display: block;
			padding: 12px 20px;
			margin: 0 0 16px;
			&:last-child {
				margin: 0;
			}
		}
	}
}

@media (max-height: 320px) {
	.overlay {
		&-inner {
			max-height: 320px;
			overflow: auto;
		}
	}
}

.booking-attention {
	border: 1px solid #e70012;
	text-align: center;
	margin: 0 0 20px;
	padding: 16px 20px;
	p {
		color: #e70012;
		margin: 0;
		svg {
			margin-right: 5px;
		}
	}
}

.privacy {
	background: #f8f8f8;
	text-align: center;
	padding: 24px;
	margin:  0 0 20px;
	input {
		margin: 0 5px 0 0;
	}
	label {
		cursor: pointer;
	}
	a {
		text-decoration: underline;
		color: #333;
		&:hover {
			opacity: .7;
			text-decoration: none;
		}
	}
}

.form {
	&-area {
		background: #f8f8f8;
		padding: 25px;
		margin: 0 0 30px;
	}
	&-item {
		display: flex;
		margin: 0 0 30px;
		&:last-child {
			margin: 0;
		}
		&-title {
			width: 20%;
			font-size: 18px;
			padding: 8px 0 0;
		}
		&-body {
			width: 80%;
			font-size: 18px;
			margin: 0;
			padding: 0;
		}
		&-full {
			display: block;
			dt {
				padding: 0;
			}
			dt ,
			dd {
				width: 100%;
			}
		}
	}
	&-submit {
		border: 1px solid #ccc;
		border-radius: 6px;
		font-size: 20px;
		padding: 10px 30px;
		background: #f8f8f8;
		cursor: pointer;
		&:hover {
			opacity: .7;
		}
		&:disabled {
			cursor: not-allowed;
			&:hover {
				opacity: 1;
			}
		}
		&-wrapper {
			text-align: center;
			margin: 0 0 40px;
			&-thanks {
				padding: 20px 0 0;
				margin: 0;
			}
			a {
				display: inline-block;
				border: 1px solid #ccc;
				border-radius: 6px;
				font-size: 20px;
				padding: 10px 30px;
				background: #f8f8f8;
				cursor: pointer;
				text-decoration: none;
				color: #000;
				&:hover {
					opacity: .7;
				}
				&:disabled {
					cursor: not-allowed;
					&:hover {
						opacity: 1;
					}
				}
			}
		}
	}
	&-textarea {
		width: 100%;
		height: 200px;
		border: 1px solid #ccc;
		border-radius: 6px;
		padding: 10px;
		box-sizing: border-box;
	}
	&-note {
		font-size: 14px;
		margin: 4px 0 0;
		padding: 0;
		&-list {
			display: flex;
			flex-wrap: wrap;
			font-size: 14px;
			margin: 4px 0 0 0;
			padding: 0;
			li {
				list-style-type: none;
				margin: 0 15px 0 0;
				&:last-child {
					margin: 0;
				}
			}
		}
	}
	&-disp {
		display: block;
		padding: 10px;
		border: 1px solid #ccc;
		background: #f0f0f0;
		color: #868686;
		line-height: 1;
		border-radius: 6px;
	}
	&-name {
		display: flex;
		justify-content: space-between;
		margin: 0;
		&-wrapper {
			width: 49%;
			align-items: center;
			display: flex;
			dt {
				width: 15%;
			}
			dd {
				margin: 0;
				padding: 0;
				width: 85%;
			}
		}
	}
	&-text {
		display: block;
		padding: 10px;
		border: 1px solid #ccc;
		line-height: 1;
		background: #fff;
		width: 100%;
		box-sizing: border-box;
		border-radius: 6px;
	}
	&-select {
		border: 1px solid #ccc;
		line-height: 1;
		background: #fff;
		border-radius: 6px;
		select {
			appearance: none;
			border-radius: 0;
			border: 0;
			margin: 0;
			padding: 10px;
			background: none transparent;
			vertical-align: middle;
			font-size: inherit;
			color: inherit;
			box-sizing: border-box;
			width: 100%;
		}
	}
}

.booking-cancel {
	border: 1px solid #e70012;
	margin: 0;
	padding: 16px 20px;
	.sp {
		display: none;
	}
	h3 {
		font-weight: bold;
		font-size: 18px;
	}
	h3 ,
	p {
		margin: 0;
		padding: 0;
		color: #e70012;
	}
	&-area {
		background: #fff4f4;
		padding: 14px 20px;
		margin: 20px 0 0;
		h4 {
			font-weight: bold;
			font-size: 18px;
		}
		h4 ,
		p {
			color: #333;
			margin: 0;
			padding: 0;
		}
		ul {
			display: flex;
			list-style-type: none;
			padding: 0;
			li {
				margin: 0 16px 0 0;
				&:last-child {
					margin: 0;
				}
			}
		}
	}
}

@media (max-width: 767px) {
	.booking-cancel {
		border: 1px solid #e70012;
		margin: 0;
		padding: 16px 20px;
		.sp {
			display: block;
		}
		h3 {
			font-size: 16px;
			font-weight: bold;
		}
		&-area {
			text-align: center;
			h4 {
				font-size: 16px;
			}
			ul {
				display: block;
				li {
					margin: 0;
				}
			}
		}
	}
}

.error {
	&-input {
		padding: 0;
		color: red;
    font-size: 14px;
    margin: 4px 0 0;
	}
}

.booking-comp {
	text-align: center;
	border: 1px solid #ccc;
	padding: 16px 20px;
	margin: 0 0 20px;
	h3 {
		margin: 0 0 16px;
		padding: 0;
		font-weight: bold;
	}
	p {
		margin: 0;
		padding: 0;
	}
}

.booking-failure {
	border: 1px solid #e70012;
	margin: 0;
	padding: 16px 10px;
	text-align: center;
	color: #e70012;
	h3 {
    color: #e70012;
		margin: 0 0 16px;
		svg {
			margin: 0 10px 0 0;
		}
	}
	p {
		margin: 0 0 14px;
		&:last-child {
			margin: 0;
		}
	}
}

@media (max-width: 767px) {
	.booking-comp {
		.pc {
			display: none;
		}
	}
	
}

@media (max-width: 767px) {
	.form {
		&-area {
			padding: 10px;
		}
		&-item {
			display: block;
			margin: 0 0 26px;
			&-title {
				margin: 0 0 5px;
				padding: 0;
			}
			&-title ,
			&-body {
				width: 100%;
			}
		}
		&-name {
			display: block;
			margin: 0;
			&-wrapper {
				width: 100%;
				margin: 0 0 5px;
				&:last-child {
					margin: 0;
				}
			}
		}
	}
}


@keyframes spinner {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0);
            transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
  }
}