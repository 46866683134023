// トップページ > メインビジュアル
.p-home-heading {
  $var: "";
    width: 100%;
    margin: 0;
    position: relative;
  &__text {
      width: 0;
      height: 0;
      overflow: hidden;
      position: absolute;
  }
}