// インフォ
.c-info {
  $var: "";
    color: #fff;
    background: #333;
    padding: 56px 20px 60px;
    margin: 40px -10px 0;
    @media #{$pc} {
      padding: 43px 20px 35px;
      margin: 80px 0 0;
    }
  &__lead {
      font-size: 18px;
      font-weight: 700;
      line-height: (28/18);
      margin: 0;
      @media #{$pc} {
        font-size: 16px;
        line-height: (28/16);
        max-width: 740px;
        margin: 0 auto;
      }
  }
  &__text {
      font-size: 16px;
      line-height: (28/16);
      margin: 17px 0 0;
      @media #{$pc} {
        max-width: 740px;
        margin: 17px auto 0;
      }
  }
  &__link{
      margin: 40px 0 0;
      @media #{$pc} {
        margin-top: 38px;
      }
  }
}