// 見出し
.c-heading {
  $var: "";
  &--lv2 {
      color: #fff;
      font-size: 24px;
      font-weight: 700;
      line-height: ceil(32/24*10)/10;
      margin: 0;
      margin-top: 51px;
      @media #{$pc} {
        font-size: 28px;
        line-height: (32/28);
        margin-top: 57px;
      }
    &:first-child {
        margin-top: 0 !important;
    }
    & + * {
        margin-top: 52px;
    }
  }
  &--lv3 {
      color: #fff;
      font-size: 20px;
      font-weight: 700;
      line-height: ceil(32/20*10)/10;
      margin: 62px 0 0;
    &:first-child {
        margin-top: 0 !important;
    }
    & + * {
        margin-top: 48px;
    }
  }
}