// スマホのときのみ表示
.forSP {
		@media #{$pc} {
			display: none;
		}
}

// PCのときのみ表示
.forPC {
		@media #{$sp} {
			display: none;
		}
}