// 観覧マナー > リード文
.p-manners-leadCopy {
  $var: "";
    font-size: 16px;
    font-weight: 400;
    line-height: ceil(36/16*10)/10;
    margin: 21px 0 0;
    @media #{$pc} {
      margin-top: 48px;
    }
}

// 観覧マナー > マナーリスト ブロック
.p-manners-list {
  $var: "";
    padding: 0 20px;
    margin-top: 47px;
    @media #{$pc} {
        max-width: 740px;
        padding: 0;
        margin: 73px auto 0;
    }
  &__heading {
      color: #fff;
      font-size: 20px;
      font-weight: 700;
      line-height: (32/20);
      margin: 62px 0 0;
      @media #{$pc} {
        text-align: center;
        margin-top: 83px;
      }
    & + * {
        margin-top: 48px;
    }
  }
  &__container {
      margin: 48px 0 0;
      @media #{$pc} {
        margin-top: 67px;
      }
  }
}