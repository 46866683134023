// 情報センターについて
.p-home-overview {
  $var: "";
  &__bgRed {
      background: #dc0000;
      position: relative;
  }
}

// センターについて
.p-home-overview-about {
  $var: "";
    display: flex;
    flex-direction: column-reverse;
    @media #{$pc} {
      flex-direction: row;
      //max-width: 1280px;
      margin: 0 auto;
    }
    @media (min-width: 1280px) {
      height: 800px;
    }
  // センターについて > テキストブロック
  &__text {
      padding: 79px 20px 73px;
      @media #{$pc} {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        //padding: 141px 60/1280*100% 0 90/1280*100%;
        padding: 60px 60/1280*100% 60px 90/1280*100%;
      }
  }
    // 見出し
    &-heading {
        margin: 0;
      &__ja {
          font-size: 30px;
          font-weight: 400;
          letter-spacing: 2.5px;
          line-height: 1;
          display: block;
          margin: 0;
          @media #{$pc} {
            font-size: 42px;
            letter-spacing: 3.5px;
          }
      }
      &__en {
          font-family: Lato;
          font-size: 11px;
          font-weight: 700;
          letter-spacing: 1.83px;
          line-height: 1;
          text-transform: uppercase;
          display: block;
          margin: 18px 0 0;
          @media #{$pc} {
            font-size: 12px;
            letter-spacing: 2px;
            margin-top: 37px;
          }
      }
    }
    // センターについて > テキストブロック > テキスト
    &__paragraph {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: -0.1px;
        line-height: round(36/16*10)/10;
        text-align: justify;
        margin: 50px 0 0;
        @media #{$pc} {
          margin-top: 47px
        }
      & + & {
          margin-top: 35px;
      }
    }
  // センターについて > 画像ブロック
  &__img {
      margin: 0;
      position: relative;
      @media #{$pc} {
        width: 50%;
      }
  }
  &__photo {
      @media #{$pc} {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
  }
  // センターについて > 画像ブロック > キャプション
  &__imgCaption {
      font-size: 12px;
      line-height: 1;
      display: flex;
      align-items: center;
      height: 30px;
      background: rgba(0, 0, 0, 0.6);
      padding: 0 20px;
      position: absolute;
      bottom: 0;
      left: 0;
  }
  // センターについて > moreボタン
  &__btn {
      margin: 0;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%);
      @media #{$pc} {
        transform: translate(-50%, 94px);
      }
  }
}


// コンテンツリンク
.p-home-overview-link {
  $var: "";
    padding: 108px 0 84px;
    @media #{$pc} {
      display: flex;
      justify-content: space-between;
      padding-top: 132px;
      padding-bottom: 120px;
    }
  &__item {
      display: flex;
      flex-direction: column-reverse;
      @media #{$pc} {
        width: 500/1100*100%;
        padding-bottom: 5px;
        position: relative;
      }
    & + & {
        margin-top: 80px;
    }
  }
  // 見出し
  &-heading {
      margin: 38px 0 0;
    &__en {
        font-family: Lato;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 2px;
        line-height: round(14/12*10)/10;
        text-align: left;
        text-transform: uppercase;
        display: block;
        margin: 0;
    }
    &__ja {
        font-size: 26px;
        font-weight: 400;
        letter-spacing: 2px;
        line-height: 1;
        text-align: left;
        display: block;
        margin: 16px 0 0;
    }
  }
  // センターについて > 画像ブロック
  &__img {
      margin: 0;
      position: relative;
      overflow: hidden;
      @media #{$pc} {
        position: static;
      }
    img {
        width: 100%;
        max-width: none;
    }
  }
  // センターについて > moreボタン
  &__btn {
      color: #fff;
      font-family: Lato;
      font-size: 16px;
      font-weight: 700;
      line-height: 1;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 118px;
      height: 118px;
      background: #000;
      margin: 0;
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translate(20px, 20px);
      @media #{$pc} {
        right: 10px;
        transform: none;
      }
  }
}