// トップページ > Information
.p-home-news {
  $var: "";
    background: #000;
    padding: 80px 0 44px;
    @media #{$pc} {
      padding-bottom: 80px;
    }
  // トップページ > Information > 見出し
  &__heading {
      font-family: Lato;
      font-size: 18px;
      font-weight: 700;
      line-height: 1;
      text-align: center;
      margin: 0;
      @media #{$pc} {
        text-align: left;
      }
  }
  // トップページ > Information > コンテナ
  &__body {
      margin: 55px 0 0;
      @media #{$pc} {
        margin-top: 40px;
        position: relative;
      }
  }
  // トップページ > Information > ボタン
  &__btns {
      margin: 38px 0 0;
      @media #{$pc} {
        line-height: 1;
        margin: 0;
        position: absolute;
        top: -57px;
        right: 0;
      }
    .c-btn {
        font-family: Lato;
        margin: 0 auto;
        @media #{$pc} {
          font-size: 14px;
          display: inline;
        }
    }
  }
}