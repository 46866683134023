.c-pageHeader {
  $var: #fff;
    //height: (200 - (14+32+14))*1px;
    height: 200px;
    background-image: linear-gradient(to right, #e80000 0%, #cd0000 100%);
    padding: 0 10px;
    margin: 0;
    overflow: hidden;
    @media #{$pc} {
      //height: (420 - (45+50+45))*1px;
      height: 420px;
    }
  &__container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      width: 100%;
      height: 100%;
      @media #{$pc} {
        max-width: 1100px;
        margin: 0 auto;
      }
  }
  &__jp {
      color: #fff;
      font-size: 18px;
      font-weight: 300;
      letter-spacing: 0.56px;
      line-height: 1;
      @media #{$pc} {
        font-size: 32px;
        letter-spacing: 1px;
        transform: translateY(10px);
      }
  }
  &__eng {
      color: #ffffff;
      font-family: "Lato", sans-serif;
      font-size: 40px;
      font-weight: 100;
      letter-spacing: 2px;
      line-height: 1;
      text-transform: uppercase;
      white-space: nowrap;
      margin-top: 10px;
      @media #{$pc} {
        font-size: 120px;
        letter-spacing: 6px;
        word-break: keep-all;
        margin-top: 10px;
        transform: translateY(10px);
      }
    &--2lines {
        font-size: 26px;
        letter-spacing: 1.3px;
        line-height: ceil(24/26*10)/10;
        margin-top: 18px;
        @media #{$pc} {
          font-size: 80px;
          letter-spacing: 4px;
          line-height: ceil(70/80*10)/10;
          transform: translateY(3px);
          margin-top: 25px;
        }
    }
    &--3lines {
        font-size: 26px;
        letter-spacing: 1.3px;
        line-height: ceil(24/26*10)/10;
        margin-top: 18px;
        @media #{$pc} {
          font-size: 60px;
          letter-spacing: 3px;
          line-height: ceil(70/60*10)/10;
          transform: translateY(10px);
          margin-top: 25px;
        }
    }
  }
}