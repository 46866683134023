.u-container {
  $var: "";
    @media #{$pc} {
      display: flex;
      justify-content: space-between;
    }
  //&__item {}
  &--2column {
    .u-container__item {
        @media #{$pc} {
          width: 520/1100*100%
        }
    }
  }
}