.p-sitemap {
  $var: "";
    margin-top: 54px;
    @media #{$pc} {
      margin-top: 47px;
    }
  &-list {
      list-style: none;
      padding: 0;
      margin: 0;
      @media #{$pc} {
        display: flex;
        flex-wrap: wrap;
        position: relative;
      }
    .p-sitemap-list:not(.is-2ndLv) {
        display: block;
      .p-sitemap-list__item {
          @media #{$pc} {
            width: 100%;
            margin-top: 30px;
          }
        &:first-child {
            margin-top: 40px;
            @media #{$pc} {
                margin-top: 30px;
            }
        }
      }
    }
    &__item {
        font-size: 16px;
        font-weight: 700;
        line-height: 1;
        padding: 0;
        margin: 0;
        @media #{$pc} {
          width: 260/1100*100%;
        }
      &.is-pc-wide {
          @media #{$pc} {
            width: 270/1100*100%; //デフォ : 290px
          }
      }
      &.is-privacy {
          @media #{$pc} {
            width: 270/1100*100%;
            margin: 0;
            position: absolute;
            bottom: 40px;
            left: 0;
          }
      }
      &.is-sitemap {
          @media #{$pc} {
            width: 270/1100*100%;
            margin: 0;
            position: absolute;
            bottom: 0;
            left: 0;
          }
      }
      & + & {
          margin-top: 40px;
          @media #{$pc} {
            margin-top: 0;
          }
      }
    }
    &__anchor {
        color: #fff;
        text-decoration: none;
    }
    &.is-2ndLv {
        margin-top: 30px;
        margin-left: 20px;
        @media #{$pc} {
            display: block;
            margin-left: 0;
        }
      .p-sitemap-list__item {
          font-size: 14px;
          width: auto;
          padding-left: 10px;
          position: relative;
        &::before {
            content: "-";
            position: absolute;
            top: 0;
            left: 0;
        }
        & + .p-sitemap-list__item {
            margin-top: 28px;
            @media #{$pc} {
              margin-top: 29px;
            }
        }
      }
    }
  }
}
