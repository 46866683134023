// ガイドブック
.p-guidebook {
  $var: "";
  &__container {
      margin: 54px 0 0;
      @media #{$pc} {
        margin-top: 65px;
      }
    & + & {
        margin-top: 53px;
        @media #{$pc} {
          margin-top: 32px;
        }
    }
  }
  &__img {
      margin: 0;
  }
  &__text {
      text-align: justify;
      margin-top: 48px;
      @media #{$pc} {
          margin-top: 65px;
      }
    & + & {
        margin-top: 38px;
    }
    .p-guidebook__img.is-pinkmap + & {
        @media #{$pc} {
          margin-top: 28px;
        }
    }
  }
}

.p-guidebook-img02 {
    width: 100%;
    max-width: 400px;
    margin: auto;
}


// ガイドブック > パンフレット配布先
.p-destination {
  $var: "";
    margin-top: 40px;
    @media #{$pc} {
      margin-top: 62px;
    }
  &__heading {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: normal;
      line-height: ceil(32/20*10)/10;
      text-align: center;
      margin: 0;
  }
  &__list {
      list-style: none;
      padding: 0;
      border-top: solid 1px #ccc;
      margin: 23px 0 0;
      @media #{$pc} {
        display: flex;
        flex-wrap: wrap;
        margin-top: 54px;
      }
  }
  &__item {
      font-size: 16px;
      font-weight: 400;
      line-height: ceil(20/16*10)/10;
      padding: 17px 20px;
      border: solid 1px #ccc;
      border-top: 0;
      @media #{$pc} {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
      }
    &:nth-child(-n+2) {
      @media #{$pc} {
        border-top: 0;
      }
    }
    &:nth-child(2n+1) {
      @media #{$pc} {
        border-right: 0;
      }
    }
  }
  &__text {
      font-size: 16px;
      font-weight: 400;
      line-height: ceil(38/16*10)/10;
      text-align: justify;
      margin: 28px 0 0;
      @media #{$pc} {
        margin-top: 68px;
      }
  }
}