// displayプロパティ用ユーティリティスタイル
.u-display--inline {
		display: inline;
}
.u-display--inlineBlock {
		display: inline-block;
}
.u-display--block {
		display: block;
}
.u-display--flex {
		display: flex;
}
.u-display--inlineFlex {
		display: inline-flex;
}
.u-display--none {
		display: none;
}
.u-display--flexWrap {
    flex-wrap: wrap;
}