// ピックアップ/おすすめコンテンツ
.c-topics {
  $var: "";
    max-width: 1100px;
    margin: 0 auto;
  // ピックアップ/おすすめコンテンツ > リスト
  &__list {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin: 0;
  }
  // ピックアップ/おすすめコンテンツ > アイテム
  &__item {
      width: calc(50% - #{1*1/2*1px});;
      padding: 0;
      margin: 0 0 0 1px;
      @media #{$pc} {
          width: calc(25% - #{2*3/4*1px});
          margin-left: 2px;
      }
    &:nth-of-type(n+3) {
        margin-top: 34px;
        @media #{$pc} {
          margin-top: 0;
        }
    }
    &:nth-of-type(n+5) {
        @media #{$pc} {
          margin-top: 111px;
        }
    }
    &:nth-of-type(2n+1) {
        margin-left: 0;
        @media #{$pc} {
          margin-left: 2px;
        }
    }
    &:nth-of-type(4n+1) {
        @media #{$pc} {
          margin-left: 0;
        }
    }
  }
  // ピックアップ/おすすめコンテンツ > リンク
  &__anchor {
      text-decoration: none;
  }
  //&__img {}
  // ピックアップ/おすすめコンテンツ > 日付
  &__date {
      color: #fff;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0.72px;
      line-height: 1;
      display: block;
      padding: 0 19px;
      margin: 21px 0 0;
      @media #{$pc} {
        margin-top: 19px;
      }
  }
  // ピックアップ/おすすめコンテンツ > タイトル　
  &__title {
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.57px;
      line-height: round(24/16*10)/10;
      display: block;
      padding: 0 19px;
      margin: 12px 0 0;
  }
}