// 料金
.c-price {
  $var: "";
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 36px;
    border: solid 1px #ccc;
}