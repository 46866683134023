@for $i from 0 through 10 {
  .u-padding_t--#{$i * 10} {
		  padding-top: #{$i * 10}px;
  }
  .u-padding_b--#{$i * 10} {
    padding-bottom: #{$i * 10}px;
  }
  .u-margin_t--#{$i * 10} {
		margin-top: #{$i * 10}px !important;
  }
  .u-margin_b--#{$i * 10} {
		margin-bottom: #{$i * 10}px;
  }
}