*,
*::before,
*::after {
    box-sizing: border-box;
}

html,
body {
    color: #fff;
    background: #444;
    min-height: 100vh;
}

body {
    font-family: sans-serif;
    line-height: (20/16);
    display: flex;
    flex-direction: column;
    word-break: break-word;
  &.noscroll {
      width: 100%;
      position: fixed;
  }
  //-ms-overflow-style: -ms-autohiding-scrollbar;
}

img {
    display: block;
    max-width: 100%;
    height: auto;
}

a {
  $var: "";
    transition: opacity .4s 0s ease-out;
  &:hover {
      opacity: .75;
  }
}