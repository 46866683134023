.l-content {
  $var: #fff;
    flex: 1 1 auto;
    overflow: hidden;
}


// コンテンツブロック
html body .c-block {
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
}
.c-block {
  $padding: 20;
      max-width: (1100+$padding*2)*1px;
  &--w720 {
      max-width: (720+$padding*2)*1px;
  }
  &--w740 {
      max-width: (740+$padding*2)*1px;
  }
  &--w980 {
      max-width: (980+$padding*2)*1px;
  }
}