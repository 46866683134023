.c-btn {
  $color: #fff;
    text-decoration: none;
    appearance: none;
    background: none;
    padding: 0;
    border: none 0;
    margin: 0;
    outline: none;
    cursor: pointer;
  // 通常ボタン
  &--regular {
      color: #fff;
      font-size: 18px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 260px;
      height: 54px;
      background: #000;
      padding: 0;
      margin: 0;
  }
  // 予約ボタン
  &--reserve {
      color: #fff;
      font-size: 18px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 260px;
      height: 54px;
      background-color: #ff0000;
      margin: auto;
      &:disabled {
        background-color: #ccc;
        cursor: no-drop;
      }
  }
}