p {
  $var: "";
    font-size: 16px;
    font-weight: 400;
    line-height: ceil(38/16*10)/10;
    text-align: left;
    margin: 25px 0 0;
}

// テキスト
.c-text {
  $var: "";
  &__note {
      font-size: 14px;
      line-height: (26/14);
  }
}