// トップページ > 新型コロナ対策の案内
.p-home-covid19 {
  $var: "";
    padding: 71px 0 80px;
    position: relative;
    @media #{$pc} {
      padding: 94px 0 100px;
    }
  &__bg {
    &--sp,
    &--pc {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
    }
    &--sp {
        background-position: -116px top, calc(100% + 116px) top;
    }
    &--pc {
        background-color: #4B4B4B;
        background-position: center top;
    }
  }
  .c-block {
    position: relative;
    z-index: 1;
  }
  &__leadCopy {
      font-size: 18px;
      font-weight: 700;
      line-height: round(28/18*10)/10;
      text-align: left;
      margin: 0;
      @media #{$pc} {
        font-size: 16px;
        line-height: round(28/16*10)/10;
      }
  }
  &__text {
      font-size: 16px;
      font-weight: 400;
      line-height: round(28/16*10)/10;
      text-align: left;
      margin: 18px 0 0;
      @media #{$pc} {
          margin-top: 16px;
      }
  }
  &__btns {
      margin: 40px 0 0;
      @media #{$pc} {
        margin-top: 38px;
      }
  }
}