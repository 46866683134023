.modaal-inner-wrapper {
  $var: "";
    padding: 0;
    @media (max-height: 1000px) {
      padding: 0;
    }
    @media #{$pc} {
      padding: 80px 25px !important;
    }
}

.modaal-container {
    box-shadow: none;
}


.modaal-container,
.modaal-content,
.modaal-content-container {
  $var: "";
    height: 100%;
    @media #{$pc} {
      height: auto;
    }
}