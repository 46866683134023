// ラベル
.c-form-label {
  $var: "";
    color: #333;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: normal;
    line-height: 1;
    text-align: left;
}


// input text
.c-form-text {
  $var: "";
    color: #333;
    font-size: 16px;
    display: block;
    width: 100%;
    height: 48px;
    background: #fff;
    padding: 0 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 11px 0 0;
  &::placeholder {
      color: #aaa;
  }
}


// textarea
.c-form-textArea {
  $var: "";
    color: #333;
    font-size: 16px;
    line-height: 1.2;
    display: block;
    width: 100%;
    height: 168px;
    background: #fff;
    padding: 12px 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 11px 0 0;
  &::placeholder {
      color: #aaa;
  }
}


// チェックボックス
.c-form-checkbox {
  $var: "";
    /*
    width: 0;
    height: 0;
    border: 0;
    */
  & + .c-form-label {
      //display: inline-block;
      //padding-left: 28px;
      padding-left: 10px;
      position: relative;
    /*
    &::before {
        content: "";
        display: block;
        width: 18px;
        height: 18px;
        border: 1px solid #979797;
        background-color: #fff;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }
    */
  }
  /*
  &:checked + .c-form-label {
    &::after {
        content: "✓";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }
  }
  */
}


// セレクトボックス
.c-form-select {
  $var: "";
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    appearance: none;
    width: 100%;
    height: 48px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background: #fff;
    padding: 0 40px 0 20px;
  &__container {
      display: block;
      max-width: 350px;
      margin: auto;
      position: relative;
  }
  &__bullet {
      width: 11px;
      height: 7px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
  }
}
select::-ms-expand {
  display: none;
}
select::-ms-value {
  background: none;
  color: #000;
}


.c-form-container {
  $var: "";
    margin: 5px 0 0;
  & + & {
      margin-top: 26px;
  }
}