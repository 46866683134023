html body .c-topicPath {
  $var: #fff;
    padding: 11px 10px 0;
    position: relative;
    z-index: 2;
    @media #{$pc} {
      padding: 21px 20px 0;
    }
}
.c-topicPath {
  $var: "";
  &__list {
      list-style: none;
      display: flex;
      align-items: center;
      padding: 0;
      margin: 0;
  }
  &__item {
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      white-space: nowrap;
      padding: 0;
      margin: 0;
      position: relative;
    & + & {
        padding-left: 25px;
        margin-left: 18px;
      &::before {
          content: "";
      }
    }
    &:nth-child(n+3):last-child {
        text-overflow: ellipsis;
        flex: 1 1 auto;
        //max-width: 5em;
        overflow: hidden;
        @media #{$pc} {
          max-width: none;
        }
    }
  }
  &__separator {
      display: block;
      width: 6px;
      height: 11px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: calc(50% - 6px);
      left: 4px;
  }
  &__anchor {
      color: #fff;
      text-decoration: none;
  }
}