// トップページ > インフォメーション
.p-home-info {
  $var: "";
    background: #333;
    padding: 30px 0 62px;
    @media #{$pc} {
      padding: 13px 0 15px;
    }
  &__inner {
      position: relative;
      @media #{$pc} {
        display: flex;
      }
  }
  &__arrow {
      display: block;
      width: 18px;
      height: 10px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      bottom: -28px;
      right: 50%;
      transform: translate(50%, 0) rotate(90deg) ;
      @media #{$pc} {
        right: 0;
        bottom: 50%;
        transform: translate(4px, 6px) rotate(90deg) ;
      }
  }
  &__heading {
      font-family: Lato;
      font-size: 16px;
      font-weight: 700;
      line-height: (32/16);
      text-align: center;
      margin: 0;
      transform: translateX(-15px);
      @media #{$pc} {
        font-size: 14px;
        line-height: round(32/14*10)/10;
        text-align: left;
        width: 97px;
        transform: none;
      }
  }
  &__body {
      @media #{$pc} {
        width: calc(100% - 97px);
        padding-left: 20px;
        padding-right: 30px;
      }
  }
  &__text {
      font-size: 14px;
      font-weight: 700;
      line-height: round(32/14*10)/10;
      //text-align: justify;
      //text-decoration: underline;
      margin: 2px 0 0;
      @media #{$pc} {
          text-overflow: ellipsis;
          white-space: nowrap;
          margin: 0;
          overflow: hidden;
      }
  }
  &__anchor {
      color: #fff;
  }
}