// Informationリスト
.c-info-list {
  // Information > リスト
  &__list {
      list-style: none;
      padding: 0;
      margin: 0;
  }
  // Information > アイテム
  &__item {
      padding: 0 0 38px;
      border-bottom: solid 1px #ddd;
      @media #{$pc} {
        display: flex;
        padding-bottom: 15px;
      }
    & + & {
        margin-top: 36px;
        @media #{$pc} {
          margin-top: 13px;
        }
    }
  }
  // Information > 日付
  &__date {
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      margin: 0;
      @media #{$pc} {
        width: 146px;
        line-height: round(28/14*10)/10;
      }
  }
  // Information > テキスト
  &__text {
      font-size: 16px;
      font-weight: 400;
      line-height: round(28/16*10)/10;
      text-align: left;
      display: block;
      margin: 5px 0 0;
      @media #{$pc} {
        width: calc(100% - 146px);
        margin: 0;
      }
  }
  // Information > リンク
  &__anchor {
      color: #fff;
  }
  // 矢印
  &__arrow {
      display: inline-block;
      width: 18px;
      height: 10px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      margin-left: 6px;
      @media #{$pc} {
        margin-left: 10px;
      }
  }
}