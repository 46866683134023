// 産業遺産を継承する
.p-home-succession {
  $var: "";
    width: 100%;
    height: 0;
    padding: 600px 0 0;
    overflow: hidden;
    position: relative;
    @media #{$pc} {
        padding-top: 520px;
    }
  &__bg {
    &--sp,
    &--pc {
      width: 100%;
      height: 100%;;
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  // 見出し
  &__heading {
      width: 300/375*100%;
      margin: 0;
      position: absolute;
      top: 160/600*100%;
      left: 50%;
      transform: translate(-50%, 0);
      @media #{$pc} {
        width: 468/1280*100%;
        top: 168/520*100%;
        transform: translate(-50%, 0);
      }
    img {
        width: 100%;
        max-width: none;
    }
  }
  // テキスト
  &__text {
      font-size: 18px;
      font-weight: 400;
      line-height: round(36/18*10)/10;
      text-align: center;
      width: 335/375*100%;
      margin: 0;
      position: absolute;
      top: 277/600*100%;
      left: 50%;
      transform: translate(-50%, 0);
      @media #{$pc} {
        top: 284/520*100%;
      }
  }
}